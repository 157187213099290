<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex flex-column">
      <v-text-field
        class="client-id"
        :label="$t('t.ClientId')"
        v-model="computedClientId"
        :rules="clientIdRules"
        :clearable="true"
      />
      <v-text-field
        class="client-secret"
        :label="$t('t.ClientSecret')"
        v-model="computedClientSecret"
        :rules="clientIdRules"
        :type="isNew ? 'text' : 'password'"
        :clearable="true"
      />
      <v-menu
        bottom
        offset-y
        content-class="menu"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <div class="d-flex my-2">
            <v-icon
              v-on="on"
              color="primary"
            >{{$icon('i.MagicAction')}}</v-icon>
          </div>
        </template>
        <v-card class="d-flex flex-row">
          <div class="d-flex flex-column">
            <v-list class="flex-grow-1">
              <v-list-item @click="setGoogle">Google</v-list-item>
              <v-list-item @click="setMicrosoft">Microsoft</v-list-item>
            </v-list>
          </div>
        </v-card>
      </v-menu>
      <v-text-field
        class="auth-url"
        :label="$t('t.AuthUrl')"
        v-model="computedAuthUrl"
        :rules="clientIdRules"
        :clearable="true"
      />
      <v-text-field
        class="token-url"
        :label="$t('t.TokenUrl')"
        v-model="computedTokenUrl"
        :rules="clientIdRules"
        :clearable="true"
      />
      <v-text-field
        class="logout-url"
        :label="$t('t.LogoutUrl')"
        v-model="computedLogoutUrl"
        :clearable="true"
      />
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      isWorking: false,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      dataClientId: null,
      dataClientSecret: null,
      dataAuthUrl: null,
      dataLogoutUrl: null,
      dataTokenUrl: null,
      clientSecretChanged: false
    }
  },
  computed: {
    computedClientId: {
      get () {
        return this.dataClientId
      },
      set (v) {
        this.dataClientId = v
        this.dataDocumentEmit()
      }
    },
    computedClientSecret: {
      get () {
        return this.dataClientSecret
      },
      set (v) {
        this.clientSecretChanged = this.dataClientSecret !== v

        this.dataClientSecret = v
        this.dataDocumentEmit()
      }
    },
    computedAuthUrl: {
      get () {
        return this.dataAuthUrl
      },
      set (v) {
        this.dataAuthUrl = v
        this.dataDocumentEmit()
      }
    },
    computedLogoutUrl: {
      get () {
        return this.dataLogoutUrl
      },
      set (v) {
        this.dataLogoutUrl = v
        this.dataDocumentEmit()
      }
    },
    computedTokenUrl: {
      get () {
        return this.dataTokenUrl
      },
      set (v) {
        this.dataTokenUrl = v
        this.dataDocumentEmit()
      }
    },
    clientIdRules () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 250
      ]
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        ty: 'open-id-connect',
        v: {
          clientId: this.dataClientId,
          clientSecret: this.dataClientSecret,
          authUrl: this.dataAuthUrl,
          logoutUrl: this.dataLogoutUrl,
          tokenUrl: this.dataTokenUrl,
          clientSecretChanged: this.clientSecretChanged
        }
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    setGoogle () {
      this.computedAuthUrl = 'https://accounts.google.com/o/oauth2/auth'
      this.computedTokenUrl = 'https://oauth2.googleapis.com/token'
      this.computedLogoutUrl = 'https://www.google.com/accounts/Logout'
    },
    setMicrosoft () {
      this.computedAuthUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize'
      this.computedTokenUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/token'
      this.computedLogoutUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout'
    }
  },
  props: {
    document: Object,
    isNew: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    document: {
      immediate: true,
      handler (d) {
        const v = d?.v
        this.dataClientId = v?.clientId
        this.dataClientSecret = v?.clientSecret
        this.dataAuthUrl = v?.authUrl
        this.dataLogoutUrl = v?.logoutUrl
        this.dataTokenUrl = v?.tokenUrl
        this.clientSecretChanged = v?.clientSecretChanged || false
      }
    }
  }
}
</script>
